.social-links {
    display: flex;
    gap: 20px;
    align-items: center;
  }
  
  .social-links.default {
    justify-content: flex-end;
  }
  
  .social-links.reach-out {
    justify-content: center;
    margin-top: 30px;
    gap: 30px;
  }
  
  .social-links a {
    color: var(--text-color, #333);
    transition: color 0.3s ease, transform 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .social-links a:hover {
    color: var(--accent-color, #007bff);
    transform: translateY(-2px);
  }
  
  /* Navbar specific styling */
  .social-links.navbar {
    margin-left: auto; /* Push to the right */
    gap: 15px; /* Smaller gap for navbar */
  }
  
  .social-links.navbar a {
    opacity: 0.8;
  }
  
  .social-links.navbar a:hover {
    opacity: 1;
  }
  
  /* About page specific styling */
  .social-links.reach-out a {
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .social-links.reach-out a:hover {
    background-color: #e8e8e8;
    box-shadow: 0 4px 8px rgba(0,0,0,0.15);
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .social-links.navbar {
      gap: 10px;
    }
  }