.projects-section {
  padding: 60px 20px;
  max-width: 1300px;
  margin: 0 auto;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin-top: 30px;
}

.project-card {
  background-color: #fff;
  padding: 25px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.project-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.project-card h3 {
  margin-top: 0;
  margin-bottom: 15px;
  color: var(--accent-color, #007bff);
  font-size: 1.5rem;
}

.text-container {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 15px;
  margin: 15px 0;
}

.project-carousel {
  position: relative;
  overflow: hidden;
  height: 300px;
  margin-bottom: 15px;
  border-radius: 4px;
  background-color: #f5f5f5;
}

.carousel-image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.carousel-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.carousel-image.active {
  opacity: 1;
}

.carousel-prev,
.carousel-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 1.2rem;
  z-index: 10;
  transition: background 0.3s ease;
}

.carousel-prev:hover,
.carousel-next:hover {
  background: rgba(0, 0, 0, 0.7);
}

.carousel-prev {
  left: 10px;
}

.carousel-next {
  right: 10px;
}

.carousel-indicators {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  gap: 8px;
  z-index: 10;
}

.indicator {
  height: 10px;
  width: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.indicator.active {
  background-color: var(--accent-color, #007bff);
}

.project-links {
  display: flex;
  gap: 15px;
  margin-top: 15px;
}

.project-links a {
  color: var(--text-color, #333);
  transition: color 0.3s ease-in-out;
  font-size: 1.5rem;
}

.project-links a:hover {
  color: var(--accent-color, #007bff);
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .projects-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .project-carousel {
    height: 250px;
  }
  
  .project-card {
    padding: 20px;
  }
}
  