.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: var(--nav-bg, #ffffff);
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.navbar.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0,0,0,0.2);
  z-index: 100;
}

.navbar-left {
  display: flex;
  align-items: center;
}

.site-name {
  margin: 0;
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--text-color, #333);
}

.nav-buttons button {
  background: none;
  border: none;
  margin-left: 20px;
  font-size: 1rem;
  cursor: pointer;
  color: var(--text-color, #333);
  transition: color 0.3s ease;
}

.nav-buttons button:hover {
  color: var(--accent-color, #007bff);
}

.navbar-right {
  display: flex;
  align-items: center;
}

.navbar-right .social-links {
  display: flex;
  gap: 15px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .navbar-right {
    display: none;
  }
  
  .navbar.scrolled .navbar-right {
    display: flex;
  }
}
