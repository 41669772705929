/* src/styles/Global.css */
:root {
  --primary-color: #2c3e50;
  --secondary-color: #34495e;
  --accent-color: #007bff;
  --text-color: #333;
  --background-color: #fff;
  --secondary-bg-color: #f5f5f5;
  --border-color: #ddd;
  --shadow: 0 2px 4px rgba(0,0,0,0.1);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.6;
  color: var(--text-color);
  background-color: var(--background-color);
}

.portfolio-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
}

section {
  scroll-margin-top: 70px;
  margin-bottom: 100px;
}

section:last-of-type {
  margin-bottom: 100px;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 20px;
  line-height: 1.2;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
  position: relative;
  display: inline-block;
  margin-bottom: 30px;
}

h2::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 50px;
  height: 3px;
  background-color: var(--accent-color);
}

h3 {
  font-size: 1.5rem;
}

p {
  margin-bottom: 15px;
}

a {
  text-decoration: none;
  color: var(--accent-color);
}

.text-container {
  line-height: 1.7;
}

/* Common button styles */
.btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: var(--accent-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: #0056b3;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }
  
  h2 {
    font-size: 1.75rem;
  }
  
  section {
    padding: 40px 15px;
    margin-bottom: 80px; /* Slightly less space on mobile */
  }
}