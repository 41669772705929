.home-section {
  padding: 60px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.home-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  margin-bottom: 30px;
}

.home-text {
  flex: 1;
}

.home-image {
  flex: 1;
  display: flex;
  justify-content: center;
}

.text-container {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  max-width: 600px;
}

.profile-picture {
  width: 300px;
  height: 300px;
  border-radius: 50%; /* Makes it a circle */
  object-fit: cover; /* Fill the container */
  border: 1px solid var(--accent-color, black);
}

/* Responsive design */
@media (max-width: 768px) {
  .home-content {
    flex-direction: column-reverse;
  }
  
  .home-text, .home-image {
    width: 100%;
  }
}
  