.about-section {
  padding: 60px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.about-content {
  margin-bottom: 30px;
}

.text-container {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-bottom: 20px;
}

.technologies-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
  margin-top: 15px;
}

.technology-item {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.bullet {
  color: var(--accent-color, #007bff);
  margin-right: 10px;
  font-size: 1.2em;
}

.interests-container {
  margin-top: 20px;
}

.technology-tabs {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 15px;
    background-color: #fafafa;
    margin-bottom: 20px;
  }
  
  .tab-headers {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  .tab-header {
    padding: 8px 16px;
    background-color: #e0e0e0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .tab-header:hover {
    background-color: #d0d0d0;
  }
  
  .tab-header.active {
    background-color: var(--accent-color, #007bff);
    color: #fff;
  }
  
  .tab-content {
    padding: 10px;
    border-top: 1px solid #ddd;
  }
  